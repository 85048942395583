// extracted by mini-css-extract-plugin
export var arrowDownIconContainer = "competenze-workshop-module--arrowDownIconContainer--TDwKa";
export var cakeIcon = "competenze-workshop-module--cakeIcon--j0-yE";
export var competenceWorkShopFirstSection = "competenze-workshop-module--competenceWorkShopFirstSection--lj-aH";
export var connectionComputers = "competenze-workshop-module--connectionComputers--OTA-F";
export var firstContainerCompetence = "competenze-workshop-module--firstContainerCompetence--bnDux";
export var imageAndTextParagraphSection = "competenze-workshop-module--imageAndTextParagraphSection--P+9by";
export var imageAndTextParagraphSectionTwo = "competenze-workshop-module--imageAndTextParagraphSectionTwo--mXSpf";
export var imageTextAndParagraphContainer = "competenze-workshop-module--imageTextAndParagraphContainer--y3b0n";
export var list = "competenze-workshop-module--list--qc7sZ";
export var listItem = "competenze-workshop-module--listItem--e6m1c";
export var listSection = "competenze-workshop-module--listSection--g4Alp";
export var positionRelative = "competenze-workshop-module--positionRelative--3NBCY";
export var scopriDiPiu = "competenze-workshop-module--scopriDiPiu--JwCdW";
export var scopriDiPiuParagraph = "competenze-workshop-module--scopriDiPiuParagraph--ibGeG";
export var valueArrow = "competenze-workshop-module--valueArrow--y4VtZ";