import React from 'react';
import {Icons} from '../../_typescript/type'
import Icon from '../../components/Icon/Icon';
import {classNames} from '../../helpers/classes';

//@ts-ignore
import * as styles from './ImageAndTextParagraph.module.css';

interface IImageAndText {
    containerClass: string;
    layout?: 'rowReverse'
    iconClassName?: string;
    icon: Icons;
    paragraph: string;
}

const ImageAndTextParagraph = ({layout, containerClass, iconClassName, icon, paragraph,}: IImageAndText) => {
    return (
        <section
            className={classNames({
                [`${containerClass}`]: true,
                [styles.rowReverseContainer]: 'rowReverse' === layout,    
            })}
        >
            <Icon className={`${iconClassName}`} icon={icon} />
            <p className={styles.paragraph}>{paragraph}</p>
        </section>
    )
}

export default ImageAndTextParagraph;
