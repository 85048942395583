import * as React from "react";
import Layout from "../components/Layout/Layout";
import HeroParagraph from "../_paragraph/HeroParagraph/HeroParagraph";
import ImageAndTextParagraph from "../_paragraph/ImageAndTextParagraph/ImageAndTextParagraph";
import Button from "../components/Button/Button";
import Icon from "../components/Icon/Icon";

import * as styles from "./competenze-workshop.module.css";

const CompetenceWorkshop = () => {
  return (
    <Layout>
      <HeroParagraph
        icon="emptyCake"
        layout="hasBackgroundImage"
        imagePostion="isFlexingOnTheRight"
        headline="Competence Workshop"
        makeColumnDesktop={true}
        src={"images/connectionComputers"}
        alt=""
      />

      <div
        className={`grid-8--mobile grid-8--tablet grid-12--desktop-small  ${styles.firstContainerCompetence}`}
      >
        {/* Competence Workshop first section */}
        <section
          className={`
                    grid-8--mobile
                    grid-8--tablet
                    grid-8--desktop-small
                    grid-offset-1--desktop-small
                    ${styles.competenceWorkShopFirstSection}
                `}
        >
          {/* competence and workshop Paragraph */}
          <p className={styles.competenceAndWorkshopParagraph}>
            <strong>
              Un intervento formativo nell’attuale contesto ha l’opportunità –
              davvero irripetibile – di trasformare una esperienza di
              cambiamento vero, rivoluzionario e concreto, in una occasione di
              arricchimento delle capacità personali.
            </strong>
          </p>
        </section>

        {/* imageAndTextParagraphSection */}
        <div className={styles.imageTextAndParagraphContainer}>
          <div>
            <ImageAndTextParagraph
              containerClass={`
                            grid-8--mobile
                            grid-8--tablet 
                            grid-12--desktop-small 
                            ${styles.imageAndTextParagraphSection}
                        `}
              iconClassName={styles.cakeIcon}
              paragraph="
                            Questo passaggio è possibile 
                            se – nell’ambito di ciascun progetto – 
                            si punta a trasformare un’esperienza che 
                            le persone hanno subito, in una occasione 
                            di apprendimento, attraverso una riflessione attenta:
                        "
              icon="cake"
            />
          </div>
          <div className={styles.arrowDownIconContainer}>
            <Icon icon="arrowIconDown" />
          </div>
        </div>

        {/* List section */}
        <section
          className={`
                    grid-6--mobile 
                    grid-offset-1--mobile
                    grid-8--tablet 
                    grid-8--desktop-small
                    grid-offset-2--desktop-small
                    ${styles.listSection}
                `}
        >
          {/* List */}
          <ul className={`grid-6--tablet grid-offset-1--tablet ${styles.list}`}>
            <li className={styles.listItem}>
              sulle competenze che ciascuno è riuscito a mettere in campo di
              fronte all’emergenza nelle sfide che ha raccolto ed ha vinto;
            </li>
            <li className={styles.listItem}>
              sulle competenze che non è riuscito a tirar fuori nelle sfide che
              ha mancato;
            </li>
            <li className={styles.listItem}>
              sul perché nelle prime ha avuto successo e nelle seconde no.
            </li>
          </ul>
        </section>

        <div className={styles.positionRelative}>
          <div className={styles.valueArrow}>
            <Icon icon="valueArrow" />
          </div>
          {/* imageAndTextParagraphSection TWO */}
          <ImageAndTextParagraph
            containerClass={`
                        grid-8--mobile 
                        grid-8--tablet 
                        grid-12--desktop-small 
                        ${styles.imageAndTextParagraphSectionTwo}
                    `}
            iconClassName={styles.connectionComputers}
            paragraph="
                        In questo modo si costruiscono 
                        le basi per ripetere i successi 
                        e per evitare nuovi fallimenti.                 
                    "
            icon="connectionComputers"
          />

          {/* Scropi di piu Section */}
          <section
            className={`grid-8--mobile grid-8--tablet grid-10--desktop-small grid-offset-1--desktop-small ${styles.scopriDiPiu}`}
          >
            {/* ScopriDiPiu Paragraph */}
            <p className={styles.scopriDiPiuParagraph}>
              In altre parole, si coglie l’occasione per{" "}
              <strong>
                trasformare un’attitudine inconsapevole in una competenza
                consolidata
              </strong>
              , da rafforzare ed affinare nel tempo e per conoscere le proprie
              aree di miglioramento con tutta la precisione necessaria ad
              intervenire per trasformarle in punti di forza.
            </p>

            {/* Button */}
            <Button layout="button" to="#contatti" label="Scopri di più" />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default CompetenceWorkshop;
